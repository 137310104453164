/*
  Spinner, @see SpinnerBars.vue
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.spin-b {
    width: 22px; height: 22px;
    line-height: 0;
}
.spin-b > div {
    display: inline-block;
    background: #FFF;
    width: 2px; height: 100%;
    animation: spin-b 1.2s infinite ease-in-out;
}
.spin-b .spin-b2 {
    animation-delay: -1.1s;
}
.spin-b .spin-b3 {
    animation-delay: -1.0s;
}
.spin-b .spin-b4 {
    animation-delay: -0.9s;
}

@keyframes spin-b {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
        background: #FFF;
    }
    20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
        background: #0096E1;
    }
}
