/*
  Buttons
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.btn-box {
    color: $blue-light;
    border-radius: 0;
    box-shadow: none !important;
    font-size: 1em;
    padding: .8em 1.5em;
    &:hover:not([disabled]) {
        color: $white;
        background: $secondary;
    }
}

.btn-delete {
    color: $gray-200;
    &:hover,
    &:active {
        color: $red;
    }
}


button[disabled] {
    cursor: default;
    color: $blue-light;
}

/*
  Small outline round button
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.btn-sor {
    @extend .btn;
    @extend .btn-sm;
    @extend .rounded-circle;
    line-height: 0;
    padding: $input-btn-padding-y-sm;
}
