/*
  Input field customisations
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.transparent-input {
    background: transparent;
    border: none;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
    border-color: $blue-dark;
}

.custom-control-input[disabled] ~ .custom-control-label::after,
.custom-control-input:disabled ~ .custom-control-label::after {
    background-color: $blue-darker;
}
