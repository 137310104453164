/*
  Styles for vue-icon dependency
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.icon {
    width: 1em;
    color: $white
}
.icon:hover {
    color: $blue;
}

/*
  Icon colours
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.icon-external-link {
    color: $blue;
}
.icon-external-link:hover,
.icon-external-link:active,
.icon-external-link:focus {
    color: $white;
}

.btn-danger .icon,
.btn-outline-danger .icon {
    color: $red;
}
.btn-danger:focus .icon,
.btn-outline-danger:focus .icon,
.btn-danger:hover .icon,
.btn-outline-danger:hover .icon,
.btn-danger:active .icon,
.btn-outline-danger:active .icon {
    color: $white;
}

.btn-warning .icon,
.btn-outline-warning .icon {
    color: $yellow;
}
.btn-warning:focus .icon,
.btn-outline-warning:focus .icon,
.btn-warning:hover .icon,
.btn-outline-warning:hover .icon,
.btn-warning:active .icon,
.btn-outline-warning:active .icon {
    color: $white;
}

/*
  Icon extensions & overrides
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.icon-link-2.broken {

}
