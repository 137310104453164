
// Our variables
@import './variables';

// Bootstrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/badge";

//@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/root";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
//@import "~bootstrap/scss/code";
//@import "~bootstrap/scss/transitions";
//@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/list-group";
//@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/spinners";

// Vendor
@import '~bootstrap-vue/src/index.scss';
@import "~vue-multiselect/dist/vue-multiselect.min.css";
@import "~@brightfish/movix-create-form/dist/movix-create-form.css";

// Project plugins
@import "plugins/modal";
@import "plugins/toast";
@import "plugins/multiselect";

// Helpers
@import "helpers/colors";
@import "helpers/spinner-bars";
@import "helpers/icons";
@import "helpers/buttons";
@import "helpers/custom-select";
@import "helpers/global";
@import "helpers/inputs";
@import "helpers/badges";
@import "helpers/tables";

// Partials
@import "partials/app-footer";
@import "partials/app-header";
@import "partials/app-header-user";
@import "partials/dashboard-nav";
@import "partials/heading";
@import "partials/progress-bar";
@import "partials/view-nav";
@import "partials/view-nav-bar";
@import "partials/view-box";
@import "partials/pagination";

// Pages
@import "views/app";
@import "views/admin";
@import "views/dashboard";
