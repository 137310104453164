/*
  View top navigation bar
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.view-nav {
    & > .container-fluid {
        min-height: $custom-select-height;
        border-bottom: 1px solid $border-color;
        & > .row > *:last-child {
            // Ensure vertical alignment is always the same;
            // 30px is height of horizontal week navigation partial.
            height: 30px;
        }
    }
    .nav-link {
        background: transparent;
        border: none;
        padding: 0 $grid-gutter-width 0 0;
        & > span {
            display: block;
            span {
                vertical-align: top;
            }
        }
    }
    .nav-link.active {
        color: $gray-100;
        & > span {
            background-size: 100% 1px;
        }
    }
}
