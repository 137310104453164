/*
  Colors
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
$blue-darkest: #1c1e2f;
$blue-darker: #222437;
$blue-dark: #2f324d;
$blue-light: #575a7d;
$blue-lighter: #6b6e99;

$blue: #0096E1;
$yellow: #efc14e;

$white: #fff;
$gray-100: #ececee;
$gray-200: #b9bac0;

$primary:       $blue;
$secondary:     $blue-dark;
//$success:       $green;
$info:          $blue;
$warning:       $yellow;
//$danger:        $red;
$light:         $blue-light;
$dark:          $blue-darker;

/*
  Layout
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

$body-bg: $blue-darkest;
$body-color: $gray-100;

$border-color: $blue-dark;

$app-header-height: 56px;
$grid-gutter-width: 24px;


/*
  Typography
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

$font-family-sans-serif: 'Source Sans Pro', sans-serif;
$font-size-base: .94rem;
$line-height-base: 1.45;

// $display1-size: 6rem;
// $display2-size: 5.5rem;
// $display3-size: 4.5rem;
$display4-size: $font-size-base * 1.5;

/*
  Form elements
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

$input-color:                           $white;
$input-bg:                              $blue-darkest;
$input-border-color:                    $blue;

$input-focus-color:                     $blue;
$input-focus-bg:                        inherit;

$custom-select-bg:                      $blue-darkest;
$custom-select-indicator-color:         $blue-lighter;

$input-group-addon-bg:                  $blue-darkest;
$input-group-addon-color:               $white;

$form-text-margin-top:                  .5rem;

$input-disabled-bg:                     $blue-dark;
$custom-control-label-disabled-color:   $blue-dark;

/*
  Tables and pagination elements
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

$table-dark-bg: $blue-darker;

$pagination-color: $white;
$pagination-bg: $blue-darker;
$pagination-border-color: $blue-dark;

$pagination-disabled-color: $blue-dark;
$pagination-disabled-bg: $blue-darker;
$pagination-disabled-border-color: $blue-dark;

/*
  Modal
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

$modal-backdrop-bg:                 rgba(14, 15, 23, .75);
$modal-backdrop-opacity:            1;
$modal-footer-margin-between:       0;

/*
  Alert colors
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.alert-danger {
    background: #FFBA0B !important;
    color: #5D370F !important;
}
