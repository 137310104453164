/*
  Modal: generic classes
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

#modal-wrap {
    pointer-events: auto;
    position: fixed; top: 0; left: 0; z-index: 9999;
    width: 100%; height: 100%;
    background: $modal-backdrop-bg;
}

#modal {
    position: absolute; top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 480px;
    min-width: 200px;
    background: #222437;
    .modal-body {
        padding: 1.2em 1.5em;
        .modal-title {
            font-size: $display4-size;
            margin-bottom: .75em;
        }
    }
    .modal-footer > div {
        display: flex;
        width: 100%;
    }
}

/*
  Modal: bootstrap overrides
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.modal {
    background: $modal-backdrop-bg;
}
#modal .modal-footer {
    padding: 0;
}

/*
  Modal: generic basic modal
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.modal-simple .modal-footer .btn-box {
    width: 100%;
}

/*
  Modal: generic prompt modal
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.modal-prompt .modal-footer {
    .btn-box {
        width: 50%;
    }
    .btn-box:first-of-type {
        border-right: 1px solid $border-color;
    }
}
