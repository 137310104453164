/*
  Main progress bar
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

#progress-bar {
    position: fixed; top: 0; left: 0; right: 0; z-index: 999999;
    width: 0; height: 2px;
    transition: width 0.2s, opacity 0.4s;
    opacity: 1;
    background-color: $yellow;
}
