/*
  Custom badge colors
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.badge-emergency {
    background: #7F1D1D;
}
.badge-alert {
    background: #991B1B;
}
.badge-critical {
    background: #B91C1C;
}
.badge-error {
    background: #DC2626;
}
.badge-warning {
    background: #D97706;
}
.badge-notice {
    background: #059669;
}
.badge-info {
    background: #2563EB;
}
.badge-debug {
    border: 1px dotted;
}
