/*
  Heading elements
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.h-label {
    line-height: 1.1;
    color: $gray-100;
    padding-bottom: $grid-gutter-width / 3;
    border-bottom: 1px solid $border-color;
    margin-bottom: 1.1em;
    & > * {
        margin-bottom: .25em;
    }
    & > .upper {
        display: block;
        font-size: $font-size-sm;
        color: $blue-light;
        .spin-b {
            float: right;
        }
    }
    & > .lower {
        display: block;
        line-height: 1;
    }
}
