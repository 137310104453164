/*
  Movie mapping view
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.shx-table {
    th {
        @extend .text-muted;
        text-transform: lowercase;
        border-top: none;
    }
    tr td:first-of-type,
    tr th:first-of-type {
        padding-left: .45rem;
    }
    tr td:last-of-type,
    tr th:last-of-type {
        padding-right: .45rem;
    }
    .shx-td-p-0 {
        padding: 0;
        vertical-align: middle;
        text-align: center;
        & > * {
            display: inline-block;
            vertical-align: middle;
        }
    }
    .shx-th-50 {
        width: 50px;
    }
    .shx-th-60 {
        width: 60px;
    }
    .shx-th-80 {
        width: 80px;
    }
    .shx-th-100 {
        width: 100px;
    }
    .shx-th-130 {
        width: 130px;
    }
    .shx-th-160 {
        width: 160px;
    }
    .shx-th-200 {
        width: 200px;
    }
    .shx-td-mvx-title {
        position: relative;
        .progress-bar-wrap {
            position: absolute; left: $table-cell-padding; bottom: 0;
            width: calc(100% - #{$table-cell-padding}) !important;
            height: 2px;
            background-color: $blue-darkest;
            .progress-bar {
                height: 2px;
                background: linear-gradient(to right, $danger, $warning, $blue);
            }
        }
    }
    .b-table-details {
        &, th, td {
            background-color: $blue-darkest;
        }
        th {
            padding: .25rem .5rem;
            font-weight: 400;
        }
        th.b-table-sort-icon-left {
            padding-left: .85rem;
        }
        td {
            padding: .25rem .5rem;
        }
    }
}
