/*
  Dashboard week nav
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.dashboard-nav-weeks-col {
    position: relative;

    &:before,
    &:after {
        content: '';
        position: absolute; top: 0; z-index: 1;
        display: block;
        width: 3em; height: 100%;
    }

    &:before {
        left: 0;
        background: linear-gradient(to right, $blue-darkest, rgba(28, 30, 47, 0) 100%);
    }

    &:after {
        right: 0;
        background: linear-gradient(to left, $blue-darkest, rgba(28, 30, 47, 0) 100%);
    }

    .nav-link {
        transition: color .8s ease;
    }
}
