/*
  View content box
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.box {
    background-color: $blue-darker;
    margin-bottom: 1.5em;
    .box-title {
        padding: 1.5em 1.5em 0 1.5em;
        font-size: $font-size-sm;
        color: $blue-light;
    }
    .box-content {
        padding: 1.5em;
        .box-content p {
            color: $gray-200;
        }
    }
    .box-footer {
        border-top: 1px solid $border-color;
        &.text-right .btn {
            border-left: 1px solid $border-color;
        }
    }
}

/*
  View content box as nav menu
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.box.nav {
    background: transparent;
    .nav-item {
        margin-bottom: .75em;
        .nav-link {
            background-color: $blue-darker;
            border-left: 5px solid $blue-darker;
            padding-left: .75rem;
            &.router-link-exact-active {
                border-left-color: $blue;
                color: $white;
                .icon {
                    color: $white;
                }
            }
            .icon {
                margin-right: .5em;
                color: $blue
            }
        }
    }
    .nav-item:last-of-type {
        margin-bottom: 0;
    }
}
