/*
  Secondary view top navigation bar
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.box.view-nav-bar {
    height: 38px;
    display: flex;
    align-items: center;

    & > * {
        border-radius: 0;
        border: none;
        border-right: 1px solid #2f324d;
    }

    & > *:last-child {
        border-right: none;
    }

    .form-control:focus {
        box-shadow: none;
    }

    .custom-select {
        background-color: transparent;
        color: $white;
        &:focus {
            box-shadow: none;
        }
    }

    .custom-switch {
        margin-left: $spacer;
        height: 100%;
        padding-top: 8px;
        padding-right: 16px;
        white-space: nowrap;
    }

    .custom-select:focus,
    .form-control:focus,
    .multiselect.multiselect--active .multiselect__input {
        color: $blue;
    }

    .multiselect,
    .multiselect .multiselect__select,
    .multiselect .multiselect__tags {
        height: 100%;
        min-height: 100%;
    }

    .multiselect .multiselect__tags,
    .multiselect .multiselect__input,
    .multiselect .multiselect__single {
        background: transparent;
        border: none;
    }

    .multiselect .multiselect__single {
        color: $white;
    }
}
