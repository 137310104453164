/*
  User menu in app header
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

#app-header .user-menu {
    display: flex;
    align-items: center;
    overflow: hidden;

    .user-nav {
        transform: translateX(calc(100% - 36px));
        transition: transform .2s ease;
        display: flex;
        align-items: center;

        a {
            display: flex;
            align-items: center;
            height: $app-header-height;
            text-decoration: none;
            position: relative;
            margin-left: $grid-gutter-width/2;
        }
    }

    .user-img {
        width: 36px;
        height: 36px;
        border-radius: 18px;
        overflow: hidden;
        background: $blue-darkest;
        text-align: center;

        span {
            text-transform: uppercase;
            color: $primary;
            font-size: 1.5em;
        }
    }

    &:hover .user-nav {
        transform: translateX(0);
    }
}
