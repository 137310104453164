/*
  Main header bar
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

#app-header {
    background-color: $blue-darker;
    border-bottom: 1px solid $blue-darkest;

    & > nav {
        height: $app-header-height;
        padding: 0 $grid-gutter-width;
        display: flex;
        justify-content: space-between;

        & > * {
            height: $app-header-height;
        }

        // Left-side menu & app logo
        & > :first-child {
            display: flex;
            align-items: flex-end;

            .nav-link {
                border-top-left-radius: $border-radius;
                border-top-right-radius: $border-radius;
            }
            .router-link-active {
                background: $blue-darkest;
            }
        }
    }
}
