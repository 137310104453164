$toast-colors: () !default;
$toast-colors: map-merge(
        (
            "success": #28a745,
            "info": #17a2b8,
            "warning": #ffc107,
            "error": #dc3545,
            "default": #343a40
        ),
        $toast-colors
);


@each $color, $value in $toast-colors {
    .toast-#{$color} {
        background-color: $value;
    }
}
.toast {
    transform: translate3d(0, 100%, 0);
    transition: transform .2s ease;
}
.toast.active {
    transform: translate3d(0, 0, 0);
}
