/*
  Dashboard view
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.stats-list {
    list-style: none;
    padding: 0;

    .stats-title span:first-of-type {
        font-size: 75%;
    }
    .stats-bar {
        height: 3px;
        background-color: $blue;
    }

    .stats-sessions > div {
        overflow-y: scroll;
    }

    .stats-sessions ol {
        padding: 1.5em 0 1.5em 1.5em;
        li {
            color: $text-muted;
        }
    }
}
