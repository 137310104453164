/*
  Bootstrap pagination component
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.pagination {
    justify-content: flex-end;
    margin-bottom: 0;

    li:first-of-type > * {
        border-left: none;
    }

    li > * {
        border-radius: 0 !important;
        border-top: none;
        border-bottom: none;
    }

    li:last-of-type > * {
        border-right: none;
    }
}
