/*
  Main structure
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

html, body {
    background: $body-bg;
    height: 100%;
}
#app,
#app .view {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
#app .view {
    justify-content: start;
    overflow-y: auto;

    .view-nav {
        padding: $grid-gutter-width/2 $grid-gutter-width 0 $grid-gutter-width;
    }
    .view-content {
        padding: $grid-gutter-width;
        height: 100%;
        overflow-y: auto;
    }
}

@media all and (max-width: 900px) {
    .view-content .row {
        display: block;
    }
    .view-content [class*="col-"] {
        width: 100%;
        max-width: none;
    }
}
