/*
  Global, resets
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

a {
    outline: none;
}

/*
  Typography
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

small, .small {
    letter-spacing: .04em;
}

/*
  Underline hover effect
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.border-hover {
    background-image: linear-gradient($primary, $primary);
    background-size: 0 0;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    transition: background .2s ease-out;

    &.border-white {
        background-image: linear-gradient($gray-100, $gray-100);
    }
    &:hover {
        background-size: 100% 1px;
    }
}

/*
  Route transitions
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
