/*
  Select dropdown component
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.custom-select {
    background-color: $blue-darkest;
    color: $primary;
    padding-left: .5em;
    &:focus {
        color: $primary;
    }
}
