/*
  Vue Multiselect package
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.multiselect {
    .multiselect__input,
    .multiselect__single,
    .multiselect__tags,
    .multiselect__content-wrapper {
        background: $blue-darkest;
    }

    .multiselect__content-wrapper {
        border: 1px solid $blue !important;
        color: $white;
    }

    .multiselect__tag {
        background: $blue;
    }

    .multiselect__tags {
        border: 1px solid $blue-darkest;
        transition: $btn-transition;
    }

    .multiselect__input {
        color: inherit;
    }

    &.multiselect--active .multiselect__tags {
        border: 1px solid $blue;
        border-bottom: none;
        box-shadow: none;
    }

    &.multiselect--active.multiselect--above .multiselect__tags {
        border-top: none;
        border-bottom: 1px solid $blue !important;
    }

    .multiselect__option--selected {
        background: $gray-900;
    }

    .multiselect__option--highlight {
        background: $blue;
    }
}
