/*
  Main app footer
  ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

#app-footer {
    position: relative;
    background: $blue-darker;
    padding: .5em $grid-gutter-width;
    border-top: 1px solid $blue-darkest;
    color: $gray-200;

    &:before {
        position: absolute; left: 0; bottom: 100%;
        content: '';
        display: block;
        width: 100%; height: 7px;
        background: linear-gradient(rgba(28, 30, 47, 0), #1c1e2f);
        pointer-events: none;
    }
}
